@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar-thumb {
  @apply bg-transparent shadow-lg;
}
::-webkit-scrollbar {
  @apply w-3 bg-transparent;
}
::-webkit-scrollbar-thumb {
  @apply rounded-none bg-green-600 /*color trackbar*/;
}
